import styles from './Guarantee.module.scss';
import Grid from '@mui/material/Grid';

import Lottie from 'lottie-react';
import Lottie_AI from 'assets/Lottie/guarantee_AI.json';
import Lottie_BigData from 'assets/Lottie/guarantee_BigData.json';
import Lottie_Solution from 'assets/Lottie/guarantee_Solution.json';
import LOttie_Pipeline from 'assets/Lottie/guarantee_PipeLine.json';

const Guarantee = () => {
  return (
    <section>
      <div className={styles.section_wrap_column}>
        <div className={`title js-scroll slide-bottom`}>
          <h1>We Are Guarantee</h1>
          <p>휴먼딥은 비즈니스의 구조를 파악하고 효율적으로 인공지능을 개발합니다.</p>
        </div>

        <div style={{ marginTop: '60px' }}>
          <Grid container className={styles.grid} >
            <Grid item className={`${styles.grid_card} js-scroll slide-left`} xs={12} sm={12} md={6} lg={6} xl={6} >
              <Lottie animationData={Lottie_AI} style={{ width: '180px' }} />
              <div className={styles.grid_card_title}>
                <h3>인공지능</h3>
                <p>자연어, 시퀀스, 이미지 등 데이터 형태에 맞는 인공지능 개발이 가능합니다.</p>
              </div>
            </Grid>

            <Grid item className={`${styles.grid_card} js-scroll slide-top`} xs={12} sm={12} md={6} lg={6} xl={6} >
              <Lottie animationData={Lottie_BigData} style={{ width: '180px' }} />
              <div className={styles.grid_card_title}>
                <h3>빅데이터</h3>
                <p>대용량의 데이터를 효율적으로 관리해 쉽고 빠르게 분석하고 활용할 수 있습니다.</p>
              </div>
            </Grid>

            <Grid item className={`${styles.grid_card} js-scroll slide-bottom`} xs={12} sm={12} md={6} lg={6} xl={6} >
              <Lottie animationData={Lottie_Solution} style={{ width: '180px' }} />
              <div className={styles.grid_card_title}>
                <h3>플랫폼</h3>
                <p>비즈니스에 맞는 플랫폼의 구조를 기획하고 UI/UX를 설계해 제공합니다.</p>
              </div>
            </Grid>

            <Grid item className={`${styles.grid_card} js-scroll slide-right`} xs={12} sm={12} md={6} lg={6} xl={6} >
              <Lottie animationData={LOttie_Pipeline} style={{ width: '180px' }} />
              <div className={styles.grid_card_title}>
                <h3>파이프라인</h3>
                <p>심플한 구조로 데이터 파이프라인을 설계하여 유지, 보수 & 확장이 간단합니다.</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  );
};

export default Guarantee;