import styles from './Platform.module.scss'
import { useState, } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';

//// 반응형 화면 미개발. 추가 개발 필요

const items = [
  // 예시로 데이터 항목들을 주석으로 남겨놓음
  {
    title: 'Medical', sub: [
      { title: 'PSG Auto Sleep Staging' },
      // { title: 'High blood pressure' },
      // { title: 'Diabetes' },
      // { title: 'Hyperlipidemia' },
    ]
  },
  { title: 'Public', sub: [] }, // typescript 특성으로 sub를 지우면 에러
  { title: 'Manufacturing', sub: [] },
]


const Platform = () => {
  const [select, setSelect] = useState('');
  const [subSelect, setSubSelect] = useState('');

  const navigate = useNavigate();

  function categoryClick(index: number): void {
    if (select !== items[index].title) {
      setSubSelect('')
    }
    setSelect(items[index].title)
  }
  function subCategoryClick(mainIndex: number, subIndex: number): void {
    setSubSelect(items[mainIndex].sub[subIndex]?.title)
  }


  return (
    <div className={styles.bg}>
      <div className={`d-flex ${styles.category}`}>
        <div className={`${styles.division} scrolled fade-in`}>
          <div>
            <KeyboardArrowUpIcon sx={{ fontSize: '60px' }} />
          </div>

          <div>
            <KeyboardArrowDownIcon sx={{ fontSize: '60px' }} />
          </div>
        </div>

        <ul className={styles.division_items}>
          {items.map((value, index) => {
            return (
              <li 
                key={index} 
                onClick={() => {
                  categoryClick(index)
                }} className={`${value.title === select ? styles.active : ''}`}
              >{value.title}</li>
            )
          })}
        </ul>

        <div className={`${styles.sub_division} `}>
          <ul>
            {/* // eslint-disable-next-line */}
            {items.map((value, index) => {
              if (value.title === select) {
                return (
                  value.sub?.map((_value, _index) => {
                    return (
                      <li
                        key={_index}
                        className={`${_value.title === subSelect ? styles.active : ''} scrolled slide-right`}
                        onClick={() => {
                          subCategoryClick(index, _index)
                        }}
                      >{_value.title}</li>
                    )
                  })
                )
              }
              return null
            })}
          </ul>
        </div>
      </div>

      <div className={styles.contents}>
        <div className={styles.content}>
          준비중입니다.
          <p className={styles.back_p}
            onClick={() => {
              navigate('/')
            }}>← Main</p>
        </div>
      </div>
    </div>
  );
};

export default Platform;