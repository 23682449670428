import { Routes, Route, Navigate } from 'react-router-dom';
import HumandeepRDMainLayout from 'layout/Humandeep/R&D';
import HumandeepRDDetailLayout from 'layout/Humandeep/R&D/Detail';
import { useLocation } from 'react-router-dom';


const RandDRouters = () => {
  const location = useLocation();
  
  return (
    <Routes>
      <Route path="" element={<HumandeepRDMainLayout />}/>
      <Route path="/result" element={ location?.state 
        ? <HumandeepRDDetailLayout />
        : <Navigate replace to={"/publications"} />
      } />      
    </Routes>
  );
};

export default RandDRouters;