import styles from './HasgTag.module.scss'


const items = [
  {title: 'Sleep', link: '/publications'},
  {title: 'Eye', link: '/publications'},
  {title: 'Foot', link: '/publications'},
]

const HashTag = () => {
  return (
    <div className={styles.hashtag_section}>
      <div className={styles.hashtag_title}>
        <h1>Hash Tage</h1>
      </div>

      <ul className={styles.tags}>
        {items.map((value, index) => {
          return (
            <li key={index}>{value.title}</li>
          )
        })}
      </ul>

    </div>
  );
};

export default HashTag;