import styles from './Achievements.module.scss'
import { useEffect } from 'react';


const Achievements = () => {
  useEffect(() => {
    function scrollRotate() {
      let image: HTMLElement | any = document.getElementById("rotate_img");
      if (image) {
      image.style.transform = "translate(-40vw) rotate(" + window.pageYOffset / 100 + "deg)";
    }
  }
    window.addEventListener('scroll',scrollRotate);
  }, [])

  return (
    <section className={styles.bg_section}>
      <div className={styles.flexible}>
        <img
          id='rotate_img'
          className={styles.bg_image}
          src={require('assets/Images/humandeep/main/earth.png')} alt="earth"
        />

        <div className={`${styles.Achievements_contents} `}>
          <div className={`${styles.Achievements_inner}`}>
            <div className='title js-scroll slide-bottom'>
              <h1>Achievements</h1>
              <p>지금까지 휴먼딥이 만들어 낸 결과들입니다.</p>
            </div>

            <div style={{ marginTop: '6.5em' }}>
              <ul className={`${styles.Achievements_content}`}>
                <li className='js-scroll slide-right'>습성황반변성 사전 예측 AI 개발</li>
                <li className='js-scroll slide-right'>수면다원검사 자동 판독 AI 개발</li>
                <li className='js-scroll slide-right'>패혈증 사전 예측 AI 개발</li>
                <li className='js-scroll slide-right'>만성질환 진단 및 사전 예측 AI 개발</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Achievements;