import styles from "./Contents.module.scss";
import SolutionCards from "./Cards"
import SolutionChart from './Charts'

function Dashboard() {
  const name = '권O영'

  return (
    <div>
      <div className={styles.card}>{name} 님의 결과</div>

      <div className={styles.card_outline}>
        <p>PSG Analysis</p>
        <SolutionCards />
        <SolutionChart />
      </div>
    </div>
  );
}

export default Dashboard;