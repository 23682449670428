import styles from './Reference.module.scss'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useRef } from "react";

import { Chart } from "react-chartjs-2";
// 라이브러리 등록
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  layout: {
    padding: 30
  },
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  responsive: true,
  plugins: {
    position: 'top' as const,
    filler: {
      propagate: false,
    },
    legend: {
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 20
        }
      },
    },
    title: {
      display: true,
      text: 'Line Chart',
      color: "orange",
      padding: {
        bottom: 20,
      },
      font: {
        size: 20
      },
      align: 'start' as const,
    },
  },
};


export const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
  datasets: [
    {
      label: 'My First Dataset',
      data:  [-21, -10, 3, 12, 34, 8, 23, 12],
      borderColor: '#FF6384',
      backgroundColor: '#FFB1C1',
      pointRadius: 5, // pointer 크기 
    } 
  ],
};


const Reference = () => {
  const chartRef = useRef<ChartJS<"line", number[], string>>(null);

  return (
    <div>
      <h1>Reference</h1>
      <p style={{fontSize: '1.7em', fontWeight: 500}}>Chart</p>
      <p style={{color: 'grey', marginLeft: '2px'}}>시각화 chart reference</p>


      
      {/* Chart */}
      <div className={styles.row}>
        <Chart 
          ref={chartRef} 
          type={"line"} 
          options={options} 
          data={data} 
          className={styles.col_6}
        />
        <Chart 
          ref={chartRef} 
          type={"line"} 
          options={options} 
          data={data} 
          className={styles.col_6}
        />
      </div>
      
      
    </div>
  );
};

export default Reference;