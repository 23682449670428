import Contents from 'pages/Humandeep/Company'
import Footer from 'pages/Common/Footer'


function HumandeepCompanyLayoutIndex() {
  return (
    <>
      <Contents />
      <Footer />
    </>
  );
}

export default HumandeepCompanyLayoutIndex;