import Contents from 'pages/Humandeep/PR'
import Footer from 'pages/Common/Footer'


function HumandeepPRLayoutIndex() {
  return (
    <>      
      <Contents />
      <Footer />
    </>
  );
}

export default HumandeepPRLayoutIndex;