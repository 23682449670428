import { Routes, Route } from 'react-router-dom';
import HugoLayout from 'layout/Hugo'
import HugoSolutionLayout from 'layout/Hugo/Solution'

const HugoRouters = () => {
  return (
    <Routes>
      <Route path="" element={<HugoLayout />} />
      <Route path="/solution" element={<HugoSolutionLayout />} />
    </Routes>
  );
};

export default HugoRouters;