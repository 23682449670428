import { Routes, Route } from 'react-router-dom';
import HumandeepLayoutIndex from 'layout/Humandeep/Main'
import HumandeepCompanyLayout from 'layout/Humandeep/Company';
import HumandeepPRLayout from 'layout/Humandeep/PR';
import HumandeepRDRoute from './R&D'
import HumandeepPlatform from 'layout/Humandeep/Platform'

const HumandeepRouters = () => {
  return (
    <Routes>
      <Route path="" element={<HumandeepLayoutIndex />} />
      <Route path="company" element={<HumandeepCompanyLayout />} />
      <Route path="pr" element={<HumandeepPRLayout />} />
      <Route path="publications/*" element={<HumandeepRDRoute />} />
      <Route path="platform" element={<HumandeepPlatform />} />
    </Routes>
  );
};

export default HumandeepRouters;