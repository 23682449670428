import styles from './Nav.module.scss'
import useScrollPostion from 'hooks/useScrollPostion'

import Logo from 'pages/Humandeep/Nav/Logo'
import Links from 'pages/Humandeep/Nav/Links'
import Hamberger from 'pages/Humandeep/Nav/Hamberger';


function Nav() {
  const scrollPosition = useScrollPostion(0);

  return (
    <nav className={scrollPosition < 10 ? `${styles.navbar}` : `${styles.navbar} ${styles.scroll}`}>
      <div className={styles.navbar_wrap}>
        {/* Logo Section */}
        <Logo />

        {/* Contents Sections */}
        <Links />

        {/* hamberger-menu */}
        <Hamberger />
      </div>  
    </nav>
  );
};

export default Nav;
