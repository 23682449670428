// Scroll Effect
export default function ScrollEffect(): void {
  // console.log('ScrollEffect 동작')
  const scrollElements: NodeListOf<Element>|any = document.querySelectorAll(".js-scroll");

  const elementInView = (el: HTMLElement | any, dividend = 1) => {
    const elementTop = el.getBoundingClientRect().top;

    return (
      elementTop <=
      (window.innerHeight || document.documentElement.clientHeight) / dividend
    );
  };

  const elementOutofView = (el: HTMLElement | any) => {
    const elementTop = el.getBoundingClientRect().top;

    return (
      elementTop > (window.innerHeight || document.documentElement.clientHeight)
    );
  };

  const displayScrollElement = (element: HTMLElement | any) => {
    element.classList.add("scrolled");
  };

  const hideScrollElement = (element: HTMLElement | any) => {
    element.classList.remove("scrolled");
  };

  const handleScrollAnimation = () => {
    // console.log(1, scrollElements)
    scrollElements?.forEach((el:any) => {
      if (elementInView(el, 1.1)) {
        displayScrollElement(el);
      } else if (elementOutofView(el)) {
        hideScrollElement(el)
      }
    })
  }
  
  window.addEventListener("scroll", () => {
    handleScrollAnimation();
  });

}



