

interface ListContentsProps {
  title: string;               
  type: string;                // CASE 'sub', 'url', 'hash';
  url: string;                 // CASE 'type: sub || hash ' => not working;
  subTitle?: SubTitleProps[];  // CASE 'type: sub' => ESSENTIAL;
  hash?: string;               // CASE 'type: hash' => ESSENTIAL;
}
// CASE 'type: hash' => 이동하고 싶은 태그에 id값을 부여하고 등록 필요

interface SubTitleProps {
  title: string;
  url: string;
}

const linkItems: ListContentsProps[] = [
  { title: "휴먼딥", type: 'sub', url: "/", subTitle: [
    {title: "이념", url: "/company"}, 
    {title: "발자취", url: "/pr"},       
  ]},
  { title: "R&D", type: 'url', url: "/publications"},
  { title: "실험실", type: 'hash', url: "/" , hash: "Labs"},
  { title: "플랫폼", type: 'url', url: "/platform" },
]

export default linkItems;