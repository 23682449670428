import styles from './Articles.module.scss'
import { useState } from 'react'
import CustomGroupButton from 'components/customGroupButton';
import ArticleContents from './Contents'
import HashTag from './HashTag';

import { 
  Box,
  InputLabel,
  MenuItem,
  FormControl,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { createTheme, ThemeProvider  } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    info: {
      main: '#081d52',
    } 
  },
});

interface Categories {
  title: string,
  active: boolean,    
}

const items: Array<Categories> = [
  { title: 'All', active: true },
  { title: 'Medical', active: false },
  { title: 'Public', active: false },
  { title: 'Manufacturing', active: false },
]


function ResearchDevelopment() {
  const [ activateComponent, setActivateComponent ] = useState<string | any>(items[0].title);  
  
  function changeCategory(subject: string) {
    setActivateComponent(subject)
  };

  const handleChange = (event: SelectChangeEvent) => {
    setActivateComponent(event.target.value as string);
  };


  return (
    <section>
      <div className={styles.section_wrap}>
        <div>
          <h2 style={{fontSize: '40px'}}>Results</h2>
          {/* case. Wide View */}
          <div>
            <div className={styles.category_btn}>
              <CustomGroupButton
                items={items}
                onChangeMode={function (subject: string) {
                  changeCategory(subject);
                }}
              />
            </div>
            {/* case. small View */}
            <div className={styles.category_dropBox}>
              <ThemeProvider theme={theme}>
                <Box>
                  <FormControl fullWidth  color='info'>
                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                    <Select
                      sx={{color: '#081d52', fontWeight: '600'}}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={activateComponent}
                      label="Category"
                      onChange={handleChange}
                    >
                      { items.map((v, i) => {
                        return (
                          <MenuItem key={i} value={v.title}>{v.title}</MenuItem>    
                        )
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </ThemeProvider>
            </div>

          </div>

        </div>
      
        <div className={styles.articles}>
          <HashTag />
          <ArticleContents activateComponent={activateComponent} />
        </div>
        
      </div>
    </section>
  );
};

export default ResearchDevelopment;