import styles from "./Labs.module.scss";
import { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from "swiper";
import "swiper/css";
import "swiper/swiper-bundle.min.css";


function Labs() {
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);
  
  return (
    <>
      <div id="Labs"></div>
      <div id={styles.Labs}>
        <h1 id="#Labs">Labs</h1>
        <p>Humandeep에서 진행한 연구를 알아보세요.</p>

        <div className={styles.content}>
          <Swiper
            spaceBetween={50}
            slidesPerView={3}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            modules={[Navigation, Mousewheel]} // 모듈추가

            mousewheel={true} // 마우스 휠
            navigation={{
              prevEl: navigationPrevRef.current ? navigationPrevRef.current : undefined,
              nextEl: navigationNextRef.current ? navigationNextRef.current : undefined,
            }}
            
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 0,
                centeredSlides: true,
              },
              768: {        
                slidesPerView: 3,
                spaceBetween: 50,
                centeredSlides: false,
              },
            }}          
          >
            <SwiperSlide className={styles.custom_slide}>
              <img src="https://picsum.photos/300?random" alt="" className={styles.slide_img} />
            </SwiperSlide>
            <SwiperSlide className={styles.custom_slide}>Slide 2</SwiperSlide>
            <SwiperSlide className={styles.custom_slide}>Slide 3</SwiperSlide>
            <SwiperSlide className={styles.custom_slide}>Slide 4</SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Labs;
