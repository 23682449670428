import styles from './Footer.module.scss';

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer_section_notice1}>
        <div className={styles.footer_section_inner}>
          <p>Introduce Us</p>
          <ul className={styles.footer_section_list}>
            <li className={styles.notice_item}>Humandeep이 연구한 솔루션을 경험해보세요.</li>
            <li className={styles.notice_item}>시계열, 비전, 영상 등 모든 형태의 데이터 분석 & AI 개발이 가능합니다.</li>
          </ul>
        </div>
      </div>
      <div className={styles.footer_section_notice2}>
        <div className={styles.footer_inner}>
          <div className={styles.footer_inner_title}>
            <img src={require('assets/Images/logo/logo.png')} alt="humandeep_logo" />
            <a href='/' className={styles.gradient_text}>Humandeep</a>
          </div>
          <ul className={styles.footer_inner_info}>
            <li>humandeep. | 대표번호: 031-698-3767</li>
            <li>Add | 경기도 성남시 분당구 판교역로 240 518-2호</li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
