import styles from './Greeting.module.scss'
import Lottie from 'lottie-react'
import CEO from 'assets/Lottie/greeting_owner.json'

const Greeting = () => {
  return (
    <section className={styles.greeting}>
      <div className={styles.greeting_display}>

        <div className={`title`}>
          <h1>
            4차 산업혁명은 우리의 미래를 어떻게 바꿀까요?
          </h1>
        </div>

        <div className={styles.greeting_text}>
          <p>
            어느 누구도 정확히 예측할 수는 없겠지만, 미래사회에 가장 큰 변화를 일으킬 수 있는<br />
            인공지능, 빅데이터, ICT 기술의 발달로 인한 '초지능화' 사회로 연결은 예상할 수 있을것입니다.
          </p>

          <p>
            우리는 이러한 기술 발전의 가속화, 다양성의 확산 속에서  AI기술의 범용화를 통해 새로운 부가가치를 창출 할 것입니다.<br />
            다양한 산업 분야, 미지의 영역, 극한의 환경 대응, 인간의 생명과 안전 등 우리가 이제까지 경험하지 못 한 새로운 세계에<br />
            AI기술 도입을 통해  즐거움, 편리함, 안전 그리고 협력과 공존의 신세계를 전달할 것입니다.
          </p>

          <p>
            우리의 인공지능 기술 연구는 여러분을 향하며, 여러분의 삶에 아주 가까이 머물 것입니다.
          </p>
        </div>

        {/* 대표님 얼굴과 서명 */}
        <div >
          <img
            src={require('assets/Images/humandeep/company/sign.png')}
            alt="sign"
            className={styles.sign}
          />

          <Lottie animationData={CEO} 
            className={styles.greeting_lottie}
          />
        </div>
      </div>
    </section>
  )
}

export default Greeting