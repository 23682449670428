import Platform from 'pages/Humandeep/Platform'


function HumandeepPlatformLayout() {
  return (
    <div>
      <Platform />
    </div>
  )
}

export default HumandeepPlatformLayout;
