import styles from "./Cards.module.scss";
import { 
  MedicalInformation, 
  PendingActions, 
  History,
  Face,
} from '@mui/icons-material';

interface info {
  age: number;
  sex: boolean;
  pastInspaction: boolean;
  pregnant: boolean;
}

interface inspactionTime {
  start: string;
  end: string;
}

interface histroy {
  counts: number;
  recent: string;
}

interface artificialIntelligence {
  activated: boolean;
  ready: boolean;
}

function SolutionCards() {  
  const info: info = {
    age: 20,
    sex: true,
    pastInspaction: false,
    pregnant: false,
  }
  const inspactionTime: inspactionTime = {
    start: '2023-03-02 / 22:01:00',
    end: '2023-03-03 / 08:48:02',
  }
  const history: histroy = {
    counts: 3,
    recent: '2023-03-02 / 22:01:00',
  }
  const artificialIntelligence: artificialIntelligence = {
    activated: true,
    ready: false,
  }

  return (
    <div className={styles.card_list}>
      <div className={`${styles.card_elevation} rounded-lg`}>
        <div className={styles.card_title}>
          <MedicalInformation />
          <p >기본 생체 정보</p>
        </div>
        <div className={styles.card_contents}>
          <p>age: <span style={{ color: 'rgba(0, 123, 203, 0.8)' }}>{info.age}</span></p>
          <p>sex: <span className={info.sex ? `${styles.true}` : `${styles.false}`}>{info.sex ? '남자' : '여자'}</span></p>
          <p>이전 검사: <span className={info.pastInspaction ? `${styles.true}` : `${styles.false}`}>{info.pastInspaction ? 'true' : 'false'}</span></p>
          <p>임신 여부: <span className={info.pregnant ? `${styles.true}` : `${styles.false}`}>{info.pregnant ? 'Yes' : 'no'}</span></p>
        </div>
      </div>

      <div className={`${styles.card_elevation} rounded-lg`}>
        <div className={styles.card_title}>
          <PendingActions />
          <p>검사 시간</p>
        </div>
        <div className={styles.card_contents}>
          <p className={styles.grid_row}>Start: <span style={{ color: 'rgba(40, 123, 150, 0.9)' }}>{inspactionTime.start}</span></p>
          <p className={styles.grid_row}>End: <span style={{ color: 'rgba(40, 123, 150, 0.9)' }}>{inspactionTime.end}</span></p>
        </div>
      </div>

      <div className={`${styles.card_elevation} rounded-lg`}>
        <div className={styles.card_title}>
          <History />
          <p>이전 기록</p>
        </div>
        <div className={styles.card_contents}>
          <p className={styles.grid_row}>History: <span style={{ color: 'rgba(40, 123, 150, 0.9)' }}>{history.counts}건</span> </p>
          <p className={styles.grid_row}>Recent (start 기준): <span style={{ color: 'rgba(40, 123, 150, 0.9)' }}>{history.recent}</span></p>
        </div>
      </div>

      <div className={`${styles.card_elevation} rounded-lg`}>
        <div className={styles.card_title}>
          <Face />
          <p>인공 지능</p>
        </div>

        <div className={styles.card_contents}>
          <p className={styles.grid_row}>Activated: <span className={artificialIntelligence.activated ? `${styles.true}` : `${styles.false}`}>{artificialIntelligence.activated ? 'true' : 'false'}</span> </p>
          <p className={styles.grid_row}>Ready: <span className={artificialIntelligence.ready ? `${styles.true}` : `${styles.false}`}>{artificialIntelligence.ready ? 'true' : 'false'}</span></p>
        </div>
      </div>
    </div>
  )
}

export default SolutionCards;