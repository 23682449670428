import styles from "./Company.module.scss";
import Introduction from 'pages/Humandeep/Company/Introduction'
import Greeting from 'pages/Humandeep/Company/Greeting'
import Direction from 'pages/Humandeep/Company/Directions'
import Lottie from 'lottie-react'
import Company_Banner from 'assets/Lottie/company_Banner.json'
import Nav from 'pages/Humandeep/Nav'


function Company() {

  return (
    <div>
      <Nav />
      {/* 상단 배너 */}
      <section className={styles.banner} >
        {/* 로티 이미지 */}
        <div className={styles.lottie_box}>
          <Lottie animationData={Company_Banner} className={styles.banner_lottie}/>
        </div>

        {/* 텍스트 */}
        <div className={`${styles.bannerText_area} scrolled slide-bottom`}>
          <div className={styles.bannerText} >
            <h1>휴먼딥은 더 나은 내일을 위한 도전에<br />끝까지 함께 할 것입니다.</h1>
          </div>
        </div>
      </section>

      {/* contents */}
      {/* 소개 */}
      <Introduction />

      {/* 대표인사말 */}
      <Greeting />

      {/* 오시는 길 */}
      <Direction />

    </div>
  )
}

export default Company;