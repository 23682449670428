import styles from './Logo.module.scss'
import useScrollPostion from 'hooks/useScrollPostion'
import { useNavigate } from 'react-router-dom';


function NavLogo() {
  const scrollPosition = useScrollPostion(0);
  const navigate = useNavigate()

  const handleNavigation = () => {
    navigate("/");
    window.scrollTo(0, 0);
  }

  return (
    <div className={styles.logo}>
      <span onClick={handleNavigation} className={styles.logo_inner}>
        <img
          src={require('assets/Images/logo/white_logo.png')}
          alt="logo"
          className={scrollPosition < 10 ? `${styles.logo_img}` : `${styles.logo_img} ${styles.logo_img_scroll}`}
        />
        <p className={scrollPosition < 10 ? `${styles.logo_p}` : `${styles.logo_p} ${styles.logo_p_scroll}`}>Humandeep</p>
      </span>
    </div>
  );
};

export default NavLogo;