import Contents from 'pages/Hugo/Contents'

function HugoLayoutIndex() {
  return (
    <>
      <Contents />
    </>
  );
}

export default HugoLayoutIndex;