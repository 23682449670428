import Navber from 'pages/Humandeep/Nav/index';
import Banner from 'pages/Humandeep/Main/Banner';
import Guarantee from 'pages/Humandeep/Main/Guarantee';
import Purpose from 'pages/Humandeep/Main/Purpose';
import RandD from 'pages/Humandeep/Main/R&D';
import Achievements from 'pages/Humandeep/Main/Achievements';
import Labs from 'pages/Humandeep/Main/Labs';
import CustomFooter from 'pages/Common/Footer';


import { useLocation } from "react-router-dom"
import { useEffect } from 'react';
import ScrollEffect from 'utils/ScrollEffect';


function HumandeepMainLayout() {

  const location = useLocation();

  useEffect(() => {
    if (location.hash !== '') {
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  useEffect(() => {
    ScrollEffect();
  }, []);

  return (
    <div>
      <Navber />

      {/* section 1 - banner */}
      <Banner />

      {/* section 2 - Purpose */}
      <Purpose />

      {/* section 4 - We Are Guarantee */}
      <Guarantee />

      {/* section 3 - Research & Development */}
      <RandD />

      {/* section 5 - Big banner */}
      <Achievements />

      {/* section 6 _ Labs */}
      <Labs />

      {/* footer */}
      <CustomFooter />
    </div>
  )
}

export default HumandeepMainLayout;
