import Router from './routes/index'
import { useEffect } from 'react';
import ScrollEffect from 'utils/ScrollEffect';

function App() {
  useEffect(() => {
    ScrollEffect()
  }, [])
  
  return (
    <Router />
  );
}

export default App;
