import styles from './Navbar.module.scss'
import { Button } from '@mui/material';

function SolutionNav() {
  const title = "hugo solution"
  const subTitle = "순천향 대학교 sleep staging R&D project"

  function getClock() {
    const D = new Date();
    const h = String(D.getHours()).padStart(2,"0");
    const m = String(D.getMinutes()).padStart(2,"0");
    const s = String(D.getSeconds()).padStart(2,"0");
    return `${h}:${m}:${s}`;
  }
  const date = getClock()

  return (
    <div className={styles.nav_wrap}>
      <div className={styles.nav_title}>
        <p>{title}</p>
        <p style={{fontSize: '.7em', color: 'grey'}}>{subTitle}</p>
      </div>

      <div className={styles.nav_info}>
        <p style={{paddingRight: '16px', fontSize: '.8em'}}>Lsat updated: {date}</p>
        <Button
          href='/'
          variant="contained"
          sx={{
            borderRadius: '12px',
            textTransform: 'none',
            '&::before': {
              visibility: 'hidden',
            },
          }}
        >Humandeep</Button>
      </div>

    </div>
  )
}

export default SolutionNav;