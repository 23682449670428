import styles from './PublicationsCard.module.scss'
import { useNavigate  } from "react-router-dom";

const PublicationsCard = (props: Array<object> | any) => {
  const navigate = useNavigate();
  const routerClick = (value: string, params: Object | Array<object> | any) => {
    if (params.detail) {
      navigate(value, {state: params});
    } else {
      alert('준비중입니다.')
    }
  }

  return (
    <>
      {props.items.map((value: Array<object> | any, index: number) => {
        return (
          <div 
            style={{position: 'relative', cursor: 'pointer'}} 
            key={index} 
            onClick={() => {
              routerClick('result', value)
            }}
          >
            <div className={`${styles.card_wrap}`} >
              {
                value.categroy === "Medical"
                  ? <span className={`medi`}>Medical</span>
                  : value.categroy === "Public"
                    ? <span className={`publ`}>Public</span>
                    : <span className={`menu`}>Menufacturing</span>
              }

              <p>{value.title}</p>

              <div className={styles.right}>
                <div className='d-flex'>
                  <p>AUTHOR</p>
                  <p >{value.author}</p>
                </div>
                <div className='d-flex'>
                  <p>PUBLICATION</p>
                  <p>{value.publication}</p>
                </div>
              </div>
            </div>

          </div>
        )
      })}
    </>

  );
};

export default PublicationsCard;