import styles from './Banner.module.scss'
import Lottie from 'lottie-react'
import Lottie_Main from 'assets/Lottie/main_banner.json'

function Banner() {
  return (
    <section className={styles.section}>
      <div className={styles.section_main_wrap}>
        <div className={styles.main_lottie_box}>
          <Lottie animationData={Lottie_Main} />
        </div>
        <div className={styles.main_contents}>
          <div className={`${styles.main_content} scrolled slide-left`}>
            <h1>We Make A<span>rtificial</span> I<span>ntelligence</span></h1>
            <p>우리의 삶을 이롭게 하기 위해,<br />나아가 세상을 이롭게하는 인공지능을 개발합니다.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;