import styles from './Contents.module.scss';
import './Transition.css'
import Dashboard from './Dashboard';
import Reference from './Reference';

import { SwitchTransition, CSSTransition } from 'react-transition-group';

const ActiveComponent = (props: string) => {
  if (props === 'Dashboard') {
    return <Dashboard/>
  } else if (props === 'Reference') {
    return <Reference/>
  }
  return <Dashboard/>
}


const Contents = (props: object|any) => {  
  return (
    <div className={styles.content_wrap}>
      <SwitchTransition mode='out-in'>
        <CSSTransition key={props.activateComponent} timeout={200} classNames='fade'>
          { ActiveComponent(props.activateComponent) }
        </CSSTransition>
      </SwitchTransition>
      
    </div>
  );
};

export default Contents;