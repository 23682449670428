import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  registerables,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ...registerables,
);

export const options = {
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  layout: {
    padding: 40
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,  //true
      position: 'top' as const,
    },
    title: {
      display: false,  //true
      text: 'Chart.js Line Chart',
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Time',
        font: {
          family: 'Comic Sans MS',
          size: 20,
          weight: 'bold',
          lineHeight: 1.2,
        },
      },
      border: { 
        color: 'grey',
        width: 2
      },  // x축 y축 
    },
    customY: {
      type: 'category' as const,
      labels: ["R", "N3", "N2", "N1", "W"],
      offset: true, // 0 기준으로 padding 주기?
      border: { 
        color: 'grey',
        width: 2
      },  // x축 y축 
      // grid: {} // 차트 내부 격자
    },
    
  },
};
const labels = [
  "22:00", "22:30",
  "23:00", "23:30",
  "24:00", "00:30",
  "01:00", "01:30",
  "02:00", "02:30",
  "03:00", "03:30",
  "04:00", "04:30",
  "05:00", "05:30",
  "06:00", "06:30",
  "07:00", "07:30",
  "08:00", "08:30",
  "09:00", "09:30",
  "10:00", "10:30",
  "11:00", "11:30",
  "12:00", "12:30",
  "13:00", "13:30",
  "14:00", "14:30",
  "15:00", "15:30", 
  "16:00", "16:30", 
  "17:00", "17:30", 
  "18:00", "18:30", 
  "19:00", "19:30",
  "20:00", "20:30",
];


export const data = {
  labels,
  datasets: [
    {
      label: 'Sleep stage',
      data: [
        'W', 'W', 'N1', 'N2', 'N3', 'R', 'N3', 'N3', 'N2','N3', 
        'N3', "R", 'N3', 'N2', 'W', 'N1', 'N3', 'N2', 'N2', 'N2', 
        'R', 'N2', 'N1', 'N1', 'N2', 'N2', 'W', 'N2', 'N2', 'N2',
        'N1', 'N3', 'R', 'R', 'R', 'N2', 'N2', 'N1', 'N2',
        'N1', 'N3', 'R', 'R', 'R', 'N2'
      ],
      // data: [1,23,4,6,7,98,9,6,4,4,7,4,54,4,7,8,67,56,34,8,9,4,],
      // borderColor: 'rgb(255, 99, 132)',  // default
      // backgroundColor: 'rgba(255, 99, 132, 0.5)',  // default
      borderColor: 'orange',
      backgroundColor: 'blue',
      yAxisID: 'customY',
      tension: 0.4,
      hoverBorderWidth: 5,
      pointHoverBorderColor: 'rgba(40, 123, 150, 0.9)',
    },

  ],
};

const Chart = () => {
  return (
    <>
      <Line options={options} data={data} style={{maxHeight: '400px'}}/>
    </>
  );

};

export default Chart;