import styles from './Purpose.module.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Purpose = () => {
  const navigate = useNavigate()

  const handleNavigation = () => {
    navigate("/company");
    window.scrollTo(0, 0);
  }

  return (
    <section>
      <div className={styles.section_wrap_row}>
        <div className={styles.purpose_contents}>
          <div className={`title ${styles.purpose_title} js-scroll slide-bottom`}>
            <h1 className='js-scroll slide-bottom'>Our Purpose</h1>
            <p className='js-scroll slide-bottom' style={{color: '#283c59', fontWeight: 600}}>
              휴먼딥은 의료, 공공, 제조 등<br />
              비즈니스에 적합한 인공지능을 개발합니다.
            </p>
            <div className={`${styles.sub_p} js-scroll slide-bottom`}>
              <p>
                데이터 파이프라인 개발부터,<br />
                인공지능 기획, 설계, 개발까지<br />
                고객과 사용자가 만족할 수 있는 서비스를 제공합니다.
              </p>
              <Button                
                onClick={handleNavigation}
                variant="contained"
                size="large"
                sx={{ marginTop: '40px', backgroundColor: '#081d52', color: 'white', fontWeight: 600, }}
              >
                자세히 보기
              </Button>
            </div>
          </div>

          <div className={`${styles.purpose_image}`}>
            <img
              className={styles.img }
              src={require('assets/Images/humandeep/main/purpose_brain.png')} 
              alt="purpose_image"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Purpose;