import PublicationsCard from 'pages/Humandeep/R&D/Articles/Common/PublicationsCard'


const Manufacturing = (props: Array<object> | any) => {
  return (
    <>
      <PublicationsCard items={props.items}/>
    </>
  );
};

export default Manufacturing;