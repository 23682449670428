import './Solution.scss'
import SleepStage from './SleepStage'

function Solution() {
  return (
    <div> 
      <SleepStage />
    </div>
  )
}

export default Solution;