import styles from './Links.module.scss'
import useScrollPostion from 'hooks/useScrollPostion'
import { useNavigate } from 'react-router-dom';

import linkItems from '../linkItems';

const NavContents = () => {
  const scrollPosition = useScrollPostion(0);
  const navigate = useNavigate();

  const addOn = (index: number):void => {
    let target = document.getElementsByClassName(`li${index}`)    
    target[0].classList.add(styles.on)
  }
  
  const removeOn = (index: number):void => {
    let target = document.getElementsByClassName(`li${index}`)
    target[0].classList.remove(styles.on)    
  }

  function handleNavigation (event: React.MouseEvent|null, value: any): void {
    if (value.type === 'hash' && value.hash) {
      event?.preventDefault()
      navigate(`/#${value.hash}`);
    } else {
      navigate(value.url)
    }
  }

  return (
    <ul className={styles.links}>
      {linkItems.map((value, index) => {
        return (
          <li
            key={index}
            className={`${styles.link_li}`}
            onMouseEnter={value.type === 'sub' ? () => { addOn(index) } : undefined}
            onMouseLeave={value.type === 'sub' ? () => { removeOn(index) } : undefined}
          >
            <span onClick={(event) => handleNavigation(event, value)}>{value.title}</span>

            {value.subTitle ?
              <ul
                className={
                  scrollPosition < 10 ?
                    `${styles.sub_link_wrap} li${index}` :
                    `${styles.sub_link_wrap} li${index} ${styles.responsive}`
                }
                onMouseEnter={() => { addOn(index) }}
              >
                {value.subTitle.map((_value, _index) => {
                  return (
                    <li key={_index} className={`${styles.sub_link_li}`}>
                      <span onClick={() => navigate(_value.url)}>{_value.title}</span>
                    </li>
                  )
                })}
              </ul> : null}
          </li>
        )
      })}
    </ul>
  );
};

export default NavContents;