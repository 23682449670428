import { useState, useEffect } from 'react';


function useScrollPostion(initalValue: number) {
  const [scrollPosition, setScrollPosition] = useState(initalValue);
  
  const updateScroll = ():void => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  }

  useEffect(() => {
    window.addEventListener('scroll', updateScroll);
  }, []);

  return scrollPosition
}

export default useScrollPostion;
