import Navbar from 'pages/Humandeep/Nav'
import Detail from 'pages/Humandeep/R&D/Detail'

function HumandeepRDLayoutIndex() {

  return (
    <>
      <Navbar/>
      <Detail />
    </>
  );
}

export default HumandeepRDLayoutIndex;