import styles from './Directions.module.scss'
import { Container as MapDiv, NaverMap, Marker, useNavermaps } from 'react-naver-maps'

function MyMap() {
  const navermaps = useNavermaps()

  return (
    <NaverMap
      defaultCenter={new navermaps.LatLng(37.4020133, 127.1107052)}
      defaultZoom={16}
    >
      <Marker position={new navermaps.LatLng(37.4020133, 127.1107052)} />
    </NaverMap>
  )
}

const Directions = () => {
  return (
    <section>
      <div className={styles.direction}>
        <div className={`title`}>
          <h1>
            오시는 길
          </h1>
        </div>

        {/* 지도 */}
        <div>
          <MapDiv className={styles.mapStyle}>
            <MyMap />
          </MapDiv>
        </div>

        {/* 주소 */}
        <div className={styles.address_section}>
          <h3>휴먼딥</h3>

          <div className={styles.address}>
            <p><b>Add.</b> </p>
            <p>(13493)경기도 성남시 분당구 판교역로 240 삼환하이펙스 A동 518-2호</p>
          </div>
          <p><b>Tell.</b>031-698-3767</p>
          <p><b>Fax.</b>070-4349-3335</p>
          <div className={styles.address}>
            <p><b>E-mail. </b></p>
            <p> admin@humandeep.co.kr</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Directions