import styles from './R&D.module.scss'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const RandD = () => {
  const navigate = useNavigate()

  const handleNavigation = () => {
    navigate("/publications");
    window.scrollTo(0, 0);
  }

  return (
    <section>
      <div className={styles.section_wrap}>
        <div className={styles.rd_contents}>
          <div className={`title ${styles.rd_title} js-scroll slide-bottom`}>
            <h1 className='js-scroll slide-bottom'>Research & Development</h1>
            <p className='js-scroll slide-bottom' style={{color: '#283c59', fontWeight: 600}}>
              휴먼딥이 진행한 조사, 연구 논문 및 초록입니다.
            </p>
            <div className={`${styles.sub_p} js-scroll slide-bottom`}>
              <p>
                공공, 의료, 제조 등<br />
                산업을 이해하고 비즈니스를 이해하기 위한<br />
                노력을 멈추지 않습니다.
              </p>
              <Button                
                onClick={handleNavigation}
                variant="contained"
                size="large"
                sx={{ marginTop: '40px', backgroundColor: '#081d52', color: 'white', fontWeight: 600, }}
              >
                자세히 보기
              </Button>
            </div>
          </div>

          <div className={`${styles.rd_image} js-scroll slide-bottom`}>
            <img
              src={require('assets/Images/humandeep/main/R&D.png')} 
              alt="rd_image" 
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default RandD;