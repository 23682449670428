import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from 'utils/ScrollToTop'

// components
import Humandeep from './Humandeep'
import Hugo from './Hugo'


const Routers = () => { 
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/*" element={<Humandeep />} />
        <Route path="/hugo/*" element={<Hugo />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;