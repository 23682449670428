import styles from './Labs.module.scss';
import Grid from '@mui/material/Grid';


const items = [
  {category: "Image", title: "이미지 글자인식 AI", content: "이미지 내 존재하는 텍스트의 인식 및 추출 수행", input: "이미지 파일", output: "인식 및 추출된 텍스트 결과물"},
  {category: "Vision", title: "영상 객체 인식 AI", content: "영상 내 존재하는 객체들의 인식 및 구분 수행", input: "비디오 파일", output: "탐지된 객체의 종류 및 위치 결과물"},
  {category: "Image", title: "이미지 깊이 추정 AI", content: "이미지의 원근감을 추정하여 색지도로 표현", input: "이미지 파일", output: "원근감 기반 색지도 이미지 결과물"},
  {category: "Image", title: "고해상도 이미지 변환 AI", content: "저해상도 이미지를 고해상도 이미지로 변환", input: "저해상도 이미지 파일", output: "고해상도 이미지 결과물"},
]

function Labs() {

  return (
    <section id='Labs'>
      <div className={styles.section_wrap_column}>
        <div className={`title js-scroll slide-bottom`}>
          <h1>Labs</h1>
          <p>휴먼딥이 자체적으로 연구하고 주시하고 있는 기술입니다.</p>
        </div>

        <div className={`${styles.labs_content} js-scroll slide-right`}>
          <p className={styles.effect_p} onClick={() => { alert('클릭') }}>→ View more</p>

          <Grid className={styles.grid} container>
            {items.map((value, index) => {
              return (
                <Grid key={index} item xs={12} sm={12} md={6} lg={3} xl={3} sx={{ paddingInline: '10px', marginBottom: '10px' }}>
                <div className={styles.lab_card}>
                  <div className={styles.lab_card_title}>
                    <span>{value.category}</span>
                    <h1>{value.title}</h1>
                    <p>{value.content}</p>
                  </div>

                  <ul className={styles.lab_card_ul}>
                    <li>Input<p>{value.input}</p></li>
                    <li>Output<p>{value.output}</p></li>
                  </ul>
                </div>
              </Grid>
              )
            })}
          </Grid>
          <p className={`${styles.effect_p_b}`} onClick={() => { alert('클릭') }}>→ View more</p>

        </div>
      </div>
    </section>
  );
};

export default Labs;