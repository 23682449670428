import { useState } from 'react';
import { Button } from '@mui/material';


const CustomButton = (props: any) => {
  const [categories, setCategories] = useState(props.items)
  
  function click(index: number) {
    let temp = [...categories]
    for (let i = 0; i < temp.length; i++) {
      temp[i].active = false
    }
    temp[index].active = true
    setCategories(temp)
    props.onChangeMode(temp[index].title)
  }

  return (
    <div>
      {categories.map((value: any, index: number) => {
        if (value.active === true) {
          return (
            <Button
              onClick={() => {
                click(index)
              }}
              key={index}
              sx={{
                textTransform: "none",
                fontFamily: 'notoSansKR',
                color: 'white',
                backgroundColor: '#081d52',
                padding: '10px 20px 10px 20px',
                fontSize: '18px',
                borderRadius: '8px',
                marginRight: '10px',
                '&:hover': {
                  color: 'white',
                  backgroundColor: '#081d52',
                },
              }}
              size="large"
            >
              {value.title}
            </Button>
          )
        } else {
          return (
            <Button
              onClick={() => {
                click(index)
              }}
              key={index}
              sx={{
                textTransform: "none",
                fontFamily: 'notoSansKR',
                color: '#081d52',
                padding: '10px 20px 10px 20px',
                fontSize: '18px',
                borderRadius: '8px',
                marginRight: '10px',
              }}
              size="large"
            >
              {value.title}
            </Button>
          )
        }
      })}
    </div>
  )

}

export default CustomButton;