import styles from './Hamberger.module.scss'
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import linkItems from '../linkItems';

const Hamberger = () => {
  const [hambergerState, setHambergerState] = useState(false);

  const hambergerClick = ():void => {
    setHambergerState(!hambergerState)
    const target = document.getElementsByClassName('hamberger')

    if (hambergerState) {
      target[0].classList.remove(styles.active)
      document.body.style.overflow = "unset";
    } else {
      target[0].classList.add(styles.active)
      document.body.style.overflow = "hidden";
    }
  }

  const hambergerInnerCategoryClick = (event: any | React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number, value: Object|any):void => {
    if (value.type === 'hash' && value.hash) {
      // console.log('해쉬 확인')
      event?.preventDefault()
      hambergerClick()
      window.location.href = process.env.REACT_APP_HOST + `#${value.hash}`
    }


    //// 990px 이하의 상태에서 하위 메뉴 있으면 돌려주는 기능    
    // 0. 기선택자가 있는지 검증 = flag
    if (value.type === 'sub') {
      // console.log('로테이트 동작')
      event?.preventDefault()
      let selects = document.getElementsByClassName('accordion_icon')
      let flag: null | number = null
      for (let i = 0; i < selects.length; i++) {
        if (selects[i].classList.contains('rotate_180')) {
          flag = i
          break
        }
      }
      
      // 1. 기선택 존재 && 새로운 선택자(index)를 선택 => 기존 선택 취소 & 새로운 선택 활성화
      if (flag !== null && flag !== index) {
        selects[flag].classList.remove('rotate_180')
        let past_sub_contents = document.getElementsByClassName(`sub_li_${flag}`)
        past_sub_contents[0].classList.remove(styles.click)
        
        selects[index].classList.add('rotate_180')
        let choice_sub_contents = document.getElementsByClassName(`sub_li_${index}`)
        choice_sub_contents[0].classList.add(styles.click)
        // 2. 기선택 존재 && 기존 선택자를 선택 => 기존 선택자 상태 변화
      } else if (flag !== null && flag === index) {
        selects[index].classList.remove('rotate_180')
        let choice_sub_contents = document.getElementsByClassName(`sub_li_${index}`)
        choice_sub_contents[0].classList.remove(styles.click)
        // 3. 기선택 X && 새로운 선택자를 선택
      } else {
        selects[index].classList.add('rotate_180')
        let choice_sub_contents = document.getElementsByClassName(`sub_li_${index}`)
        choice_sub_contents[0].classList.add(styles.click)
      }
    }

  }


  return (
    <>
      <div className={styles.hamburger} onClick={hambergerClick}>
        <div className={`${styles.hamburger_wrap} hamberger`}>
          <span className={`${styles.line}`}></span>
        </div>
      </div>

      <div className={hambergerState !== true ? `${styles.hamberger_menu}` : `${styles.hamberger_menu} ${styles.open}`}>
        <div className={styles.hamberger_menu_inner}>

          <ul className={styles.hamberger_menu_list_wrap}>
            {linkItems.map((value, index) => {
              return (
                <li key={index} className={styles.hamberger_menu_list}>

                  <a 
                    href={value.url} 
                    onClick={(event) => { hambergerInnerCategoryClick(event, index, value) }}
                  >
                    <p>{value.title}</p>
                    {value.subTitle ? <KeyboardArrowDownIcon className={`accordion_icon`} sx={{ color: 'white', fontSize: '30px' }} /> : undefined}
                  </a>


                  <ul className={`${styles.hamberger_sub_menu_list_wrap} sub_li_${index}`}>
                    {value.subTitle?.map((_value, _index) => {
                      return (
                        <li className={styles.hamberger_sub_menu_list} key={_index}>
                          <a href={_value.url}>
                            <p>{_value.title}</p>
                          </a>
                        </li>
                      )
                    })}
                  </ul>

                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Hamberger;