import styles from './PR.module.scss';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Lottie from 'lottie-react'
import PR_Banner from 'assets/Lottie/pr_Banner.json'
import Nav from 'pages/Humandeep/Nav'


// 아코디언 스타일(선 굵기 및 투명도)
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `2px solid rgba(8, 29, 82, .5)`,
  borderBottom: `2px solid rgba(8, 29, 82, .5)`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

// 아코디언 스타일(칸 너비)
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: '80px',
  flexDirection: 'row',
  '& .MuiAccordionSummary-Mui-expanded.expandIconWrapper': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

// 아코디언 스타일(날짜)
const TypographyMonth = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontFamily: 'NotoSansKR',
  fontSize: 'clamp(16px, 1.2vw, 1.2em)',
  width: '5%',
  textAlign: 'center',
  padding: '0',
  marginRight: '10%'
}));

// 아코디언 스타일(제목)
const TypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: 'clamp(16px, 1.2vw, 1.2em)',
  fontFamily: 'NotoSansKR',
  width: '80%',
  textAlign: 'start'
}));

// 아코디언 스타일(디테일)
const TypographyDetail = styled(Typography)(({ theme }) => ({
  fontSize: 'clamp(16px, 0.9vw, 1.1em)',
  fontFamily: 'NotoSansKR',
  marginLeft: '15%',
  width: '80%',

  textAlign: 'start'
}));
const AccordionDetail = styled(AccordionDetails)(({ theme }) => ({
  paddingTop: '20px',
  paddingBottom: '20px',
}));

function PR() {
  // 아코디언 내용 중 디테일 없는창은 안열리게 세팅
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  // 사업수주 데이터
  const data = [
    {
      year: 2023,
      data: [
        {
          id: "panel1",
          month: "6월",
          title: "의료데이터 공동 활용연구 프로젝트 참여  (한국보건의료정보원)",
        },
        {
          id: "panel2",
          month: "5월",
          title: "글로벌 기업 협업 프로그램 창업기업 선정 (중소벤처기업부)",
          details: "ㆍ과제명 : 인공지능을 활용한 수면다원검사(PSG) 내 수면 단계 자동 판독 솔루션"
        }
      ]
    },

    {
      year: 2022,
      data: [
        {
          id: "panel3",
          month: "10월",
          title: "미래리빙랩 공모사업 ( 충남대학교)",
          details: "ㆍ과제명 : 실행리빙랩 REHAB NOTE 앱 개발 연구용역"
        },
        {
          id: "panel4",
          month: "6월",
          title: "지역대학우수과학자지원사업 (건양대학교 산학협력단)",
          details: "ㆍ과제명 : 소비자 중심의 의약품 이상사례 보고 활성화를 위한 PHR 기반 의약품 모니터링 앱 개발역"
        },
        {
          id: "panel5",
          month: "5월",
          title: "데이터바우처 지원사업 (한국데이터산업진흥원)",
          details: "ㆍ과제명 : AI를 이용한 습성황반변성 질환 발생 예측 서비스 개발  → (수요)건양의료재단 김안과병원 "
        },
        {
          id: "panel6",
          month: "4월",
          title: "AI바우처 지원사업 (정보통신산업진흥원)     ",
          details: "ㆍ과제명 : 외래환자 데이터를 활용한 만성질환 예측 및 합병증 예방 AI솔루션 → (주관)생생의원"
        }
      ]
    },

    {
      year: 2021,
      data: [
        {
          id: "panel7",
          month: "10월",
          title: "수면다원검사 판독 자동화 및 불면 진단 연구",
          details: "ㆍ(주관)순천향대학교부속 천안병원(신경과)"
        },
        {
          id: "panel8",
          month: "5월",
          title: "인공지능 학습용 데이터 구축 사업 참여 (한국지능정보사회진흥원)",
          details: "ㆍ과제명 : 수면장애 영상 및 음성질환 데이터 구축 → (주관)서울대병원 "
        },
        {
          id: "panel9",
          month: "3월",
          title: "기업부설 연구소 설립, 벤처기업확인 (확신성장유형)",
        }
      ]
    },

    {
      year: 2020,
      data: [
        {
          id: "panel10",
          month: "10월",
          title: "산ㆍ학교류협약 체결",
          details: "ㆍ건양대학교산학협력단, 건양대학교의료인공지능학과"
        },
        {
          id: "panel11",
          month: "9월",
          title: "예비창업패키지 사업 선정 (한국보건산업진흥원)",
          details: "ㆍ과제명 : 병실 모니터링 데이터를 이용한 딥러닝 기반 패혈증 환자 상태예측시스템"
        },
        {
          id: "panel12",
          month: "9월",
          title: " 법인 설립",
        }
      ]
    }

  ]

  return (
    <div>
      <Nav />

      {/* 배너 */}
      <div className={styles.banner}>
        {/* 로티이미지 */}
        <div className={styles.lottie_box}>
          <Lottie animationData={PR_Banner} className={styles.banner_lottie}/>
        </div>

        {/* 텍스트 */}
        <div className={styles.banner_text_area}>
          <div className={`${styles.banner_text} scrolled slide-bottom`}>
            <h1>AI로 삶을 즐겁게</h1>
            <h1>보다 나은 미래를 창조합니다.</h1>
          </div>
        </div>
      </div>


      {/* contents */}

      {/* 사업수주내용 */}
      {data.map((yearData) => {
        return (
          <section className={styles.section} key={yearData.year}>
            <h1 className={styles.year}>{yearData.year}년</h1>
            {yearData.data.map((accordion) => {
              const { id, month, title, details } = accordion;
              const hasDetails = Boolean(details);
              return (

                <Accordion
                  expanded={expanded === id}
                  key={id}
                  onChange={hasDetails ? handleChange(id) : undefined}
                >
                  <AccordionSummary
                    expandIcon={hasDetails ? <ExpandMoreIcon /> : null}
                    aria-controls="panel1bh-content"
                  >
                    <TypographyMonth >{month}</TypographyMonth>
                    <TypographyTitle>{title}</TypographyTitle>
                  </AccordionSummary>
                  {hasDetails && (
                    <AccordionDetail>
                      <TypographyDetail>
                        {details}
                      </TypographyDetail>
                    </AccordionDetail>
                  )}
                </Accordion>
              );
            })}
          </section>
        );
      })}


    </div>
  )
}

export default PR;
