import styles from "./Products.module.scss";
import { Button } from '@mui/material';


function Products(props: any) {  
  return (
    <>
      <div id="Products"></div>
      <div id={styles.Products}>
        <h1>Products</h1>
        <p>Humandeep에서 개발한 솔루션을 경험해보세요.</p>

        <div className={styles.content}>
          <img src={require('assets/Images/hugo/sleepStage.png')} alt="" loading="lazy" width="500px"/>
          <div className={styles.text_box}>
            <p>수면다원검사(PSG) 판독 인공지능</p>
            <h2>Well Sleep</h2>
            <p className={styles.text_box_articles}>순천향대학교 부속 천안병원 협력 개발</p>

            <Button
              href="/hugo/solution"
              variant="contained"
              sx={{marginTop: '40px', backgroundColor: '#283c59'}}
            >
              자세히 보기
            </Button>
          </div>
        </div>
      </div>
    </>

  );
}

export default Products;
