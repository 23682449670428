import { useState } from 'react'
import styles from './SleepStage.module.scss'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import Contents from './Contents'

function Solution() {
  const [activateComponent, setActivateComponent] = useState<string | any>('Dashboard');
  function detectComponent(value: String) {
    setActivateComponent(value)
  };

  return (
    <div className={styles.horizontal}>
      <Sidebar detectComponent={detectComponent}/>

      <div className={styles.contents}>
        <Navbar />
        <Contents activateComponent={activateComponent}/>
      </div>
    </div>
  )
}

export default Solution;