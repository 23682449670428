import styles from './Detail.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';

const Result = (props: Array<object> | any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location?.state 

  return (
    <div>
      <section className={styles.section}>
        <div className={`${styles.main_content} scrolled slide-bottom`}>
          <div className={styles.section_left}>
            <p onClick={() => {
              navigate(-1)
            }}>← to List</p>
          </div>
          <div className={styles.section_right}>
            <h1 style={{ marginRight: '0px', width: '100%' }}>{item.title}</h1>
            <p style={{ marginTop: '60px', color: 'grey', }}>@ {item.author}</p>
          </div>
        </div>
      </section>

      <section>
        <div className={`${styles.main_content} ${styles.bottom} scrolled slide-bottom`}>
          <div className={`${styles.left} ${styles.basic_info}`}>
            <h1>AUTHORS</h1>
            <p>{ item.detail[0]?.Authors[0] }</p>
            <p style={{marginTop: '60px', color: 'grey', fontWeight: '300'}}>{ item.detail[0]?.Authors[1] }</p>

            <h2 style={{marginTop: '100px'}}>PUBLISHED</h2>
            <p>{ item.detail[0]?.Published }</p>
          </div>

          <div className={styles.right}>
            <h1>Abstract</h1>

            <div style={{marginTop: '30px'}}>
              <h2>Introduction</h2>
              <p>{item.detail[0]?.Introduction}</p>
            </div>

            <div>
              <h2>Material & Method</h2>
              <p>{item.detail[0]?.MaterialAndMethod}</p>
            </div>

            <div>
              <h2>Results</h2>
              <p>{item.detail[0]?.Results}</p>
            </div>

            <div>
              <h2>Conclusion</h2>
              <p>{item.detail[0]?.Conclusion}</p>
            </div>

            <div>
              <a 
                href={`${item.detail[0]?.Link}`} 
                className={styles.link} 
                target='_blank'
                rel="noreferrer"
              >View More</a>
            </div>
            

            <p className={styles.back_p}
              onClick={() => {
                navigate(-1)
              }}>← to List</p>
          </div>
        </div>
      </section>

    </div>
  );
};

export default Result;