import styles from "./Contents.module.scss";
import { useEffect } from "react";
import Products from "./Products"
import Labs from "./Labs";

function Hugo() {
  useEffect(() => {
    const scrollElements = document.querySelectorAll(".js-scroll");
  
    const elementInView = (el: HTMLElement | any, dividend = 1) => {
      const elementTop = el.getBoundingClientRect().top;
      
      return (
        elementTop <=
        (window.innerHeight || document.documentElement.clientHeight) / dividend
      );
    };
  
    const elementOutofView = (el: HTMLElement | any) => {
      const elementTop = el.getBoundingClientRect().top;
  
      return (
        elementTop > (window.innerHeight || document.documentElement.clientHeight)
      );
    };
  
    const displayScrollElement = (element: HTMLElement | any) => {
      element.classList.add("scrolled");
    };
  
    const hideScrollElement = (element: HTMLElement | any) => {
      element.classList.remove("scrolled");
    };
  
    const handleScrollAnimation = () => {
      scrollElements.forEach((el) => {
        if (elementInView(el, 1.5)) {
          displayScrollElement(el);
        } else if (elementOutofView(el)) {
          hideScrollElement(el)
        }
      })
    }
  
    window.addEventListener("scroll", () => { 
      handleScrollAnimation();
    });
  })


  return (
    <div id={styles.wrap}>
      <img src={require('assets/Images/hugo/hugo_main_02.jpg')} alt="main_image" />
        
      <div className="js-scroll fade-in">
        <div className={styles.title}>
          <h1>HUGO</h1>
          <h2>휴먼딥 AI 솔루션 플랫폼</h2>
          <p>휴먼딥의 기술과 사업을 소개합니다.</p>
        </div>
      </div>    
    
      <div className="js-scroll fade-in-bottom">
        <Products />
      </div>    
    
      <div className="js-scroll slide-left">
        <Labs />
      </div>
    </div>
  );
}

export default Hugo;
