import styles from "./Introduction.module.scss";


const Introduction = () => {
  return (
    <section className={styles.intro}>
      <div className={`title`}>
        <p>사람과 세상을 더욱 이롭게</p>
        <h1>AI의 시작</h1>
      </div>

      <div className={styles.intro_text}>
        <p>
          <b>㈜휴먼딥</b>은 AI 기술의 광범위한 활용 가능성을 탐구하고 활용하여, 고객에게 특화 된 솔루션을 제공합니다. <br />
          이를 통해 일상의 품질을 향상시키고, 사람들의 삶을 더 풍요롭게 바꿀 수 있도록 노력하고 있습니다.<br />
        </p>

        <p>
          변화하는 미래에 유연하게 대응할 수 있도록 기술을 탐구하고 자체적인 연구를 통해 사회 다방면에 인공지능을 도입하고 있습니다.<br />
          사람을 이롭게하는 인공지능을 개발하고, 보다 나은 세상을 만들어가는데 기여하겠습니다.
        </p>
      </div>
    </section>
  )
}

export default Introduction
