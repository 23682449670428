import styles from './Banner.module.scss'

const banner = () => {
  return (
    <section className={styles.section}>
      <div className={styles.section_main_wrap}>       
        <div className={styles.main_contents}>
          <div className={`${styles.main_content} scrolled slide-bottom`}>
            <h1>휴먼딥이 연구한 기술 및 초록입니다.</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default banner;