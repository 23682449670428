import Banner from 'pages/Humandeep/R&D/Banner';
import Articles from 'pages/Humandeep/R&D/Articles';
import Navbar from 'pages/Humandeep/Nav';


function HumandeepRDLayoutIndex() {
  return (
    <>
      <Navbar/>
      <Banner />
      <Articles />
    </>
  );
}

export default HumandeepRDLayoutIndex;