import styles from "./Sidebar.module.scss"
import { useState, useEffect } from "react"
import SvgIcon from "@mui/material/SvgIcon";
import { 
  Button, 
  IconButton, 
  Menu, 
  MenuItem,
} from '@mui/material';

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function SolutionSidebar(props: any) {
  useEffect(() => {
    var btns = document.getElementsByClassName('route')
    btns[0].classList.add('is_Active')
  }, [])

  const isActive = (event: any): void => {
    var btns = document.getElementsByClassName('route')
    for (var i = 0; i < btns.length; i++) {
      btns[i].classList.remove('is_Active')
    }
    event.target.classList.add('is_Active')
  }

  // footer menu method
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const paths = [
    { title: "Dashboard", icon: DashboardOutlinedIcon },
    { title: "Reference", icon: FileCopyOutlinedIcon },
    { title: "Projects", icon: InventoryOutlinedIcon, subpropertys: [
        { title: "Sleep staging" },
        { title: "재활 모바일 어플" },
      ]
    },
    { title: "Lab", icon: ScienceOutlinedIcon},
  ]

  return (
    <div className={styles.side_bar}>
      <div className={styles.sidebar_wrap}>
        {/* title */}
        <div className={styles.title}>
          <img src={require('assets/Images/logo/logo.png')} alt="humandeep_logo"/>
          <p>Hugo Solution</p>
        </div>

        {/* contents */}
        <div className={styles.contents}>
          <div>
            {paths.map((item, i) => {
              return (
                <div key={i} className="rounded-lg">                  
                  <a href='/' className={`${styles.content_title} rounded-lg route`} onClick={(event)=>{
                    event.preventDefault();
                    isActive(event)
                    props.detectComponent(item.title)
                  }}>
                    <SvgIcon component={item.icon} className={styles.icon} />
                    {item.title}
                  </a>
                  {item.subpropertys?.map((subproperty, j)=>{
                    return (
                      <div key={j} className={styles.subproperty}>                        
                        <a href="/" className={`${styles.subproperty_item} route`} onClick={(event)=>{
                          event.preventDefault();
                          isActive(event)
                          props.detectComponent(subproperty.title)
                        }}>
                          {subproperty.title}
                        </a>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
        
        {/* footer */}
        <div className={styles.accounts}>
          <Button
            disableElevation 
            fullWidth
            sx={{
              backgroundColor: 'white',
              borderRadius: '50px',
              paddingBlock: '8px',
              marginBottom: '16px',
              fontWeight: 600,
              fontSize: '1rem',
              color: 'grey',
              maxHeight: '36px',
            }}
          >공지사항</Button>
          <div className={styles.accounts_wrap}>
            <img src={require('assets/Images/hugo/user-icon.png')} alt="user_icon"/>

            <div>
              <p style={{fontWeight: 600}}>admin</p>
              <p style={{fontWeight: 600, fontSize: '.7em'}}>copyright | humandeep</p>
            </div>

            <IconButton
              sx={{
                marginLeft: 'auto',
                marginBlock: 'auto'
              }}
              aria-label="MoreVertIcon" 
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
              <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                PaperProps={{
                  style: {
                    marginLeft: "40px",
                    borderRadius: "20px",
                    transform: "translateY(-5px)",
                  }
                }}                
              >
                <MenuItem onClick={handleClose} className={styles.menuItem}>Profile</MenuItem>
                <MenuItem onClick={handleClose} className={styles.menuItem}>Logout</MenuItem>
              </Menu>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default SolutionSidebar;